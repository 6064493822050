/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'

export default function Counter ({ initNumber, initFinalNumber, initDate }) {
  const initialNum = initNumber
  const finalNumb = initFinalNumber

  const initialDate = new Date(initDate).getTime()
  const currentDate = new Date().getTime()
  const finalDate = new Date('Dec 31, 2024 18:00:00').getTime()

  const qtdSum = finalNumb - initialNum
  const qtdDate = (currentDate - initialDate) / (finalDate - initialDate)
  const countRest = (qtdSum * qtdDate) + initialNum

  const [counter, setCounter] = useState(Math.round(countRest))

  const maxCounter = finalNumb

  let myInterval
  useEffect(() => {
    myInterval = setInterval(() => {
      // if (counter === maxCounter) {
      //   console.log('entrou')
      //   clearInterval(myInterval)
      // }
      setCounter(counter => counter + 1)
    }, 1000)
  }, [])

  return (
    <h4 className='text-lwart-blue mt-1'>{counter.toLocaleString('pt-br')}</h4>
  )
}
